<template>
  <div>
    <Modal v-model="showModal" width="60%">
      <p slot="header" class="text-center">服务费配置</p>
      <div class="m-b-10">
        <p class="title p-b-10">已设置服务费</p>
        <div v-if="serviceCostList.length">
          <Tag class="m-r-5" v-for="(item, index) in serviceCostList" :key="index" color="primary">{{ item.name
            }}-刊例价：{{ fomartMoney(item.price) }}，结算价：{{ fomartMoney(item.statementPrice) }}
          </Tag>
        </div>
        <div v-else>暂未设置</div>
      </div>
      <div>
        <p class="title p-b-10">可设置服务费</p>
        <Row :gutter="8" class="m-b-10">
          <i-col span="6">
            <i-input v-model="query.keyword" size="small" placeholder="输入服务费名称关键字"></i-input>
          </i-col>
          <i-col span="18">
            <i-button size="small" class="m-r-5" type="primary" icon="ios-search" @click="handleSearch">搜索</i-button>
          </i-col>
        </Row>
        <Table size="small" max-height="580" stripe :data="showList" :columns="tableColumns" :loading="dataLoading"
          @on-selection-change="onSelectionChange"></Table>
      </div>
      <div slot="footer">
        <Button type="text" class="m-r-5" @click="showModal = false">取消</Button>
        <Button type="primary" :disabled="serviceItemIds.length === 0" @click="handleSaveProduce">保存</Button>
      </div>
    </Modal>
  </div>
</template>

<script>
import { toMoney } from '@/utils/wnumb_own'
import { cloneDeep } from 'lodash'
import { list } from '@/api/product/serviceItemPrices'
import { getServiceItemBySku, updateServiceItem } from '@/api/product/productskuedit'
export default {
  props: {
    publisherId: [Number],
    saveSuccessFun: {
      type: Function,
      default: () => { }
    }
  },
  data () {
    return {
      showModal: false,
      productskuId: undefined,
      productId: undefined,
      query: {
        publisherId: undefined,
        enabled: true,
        keyword: ''
      },
      serviceItemIds: [],
      serviceCostList: [],
      costDictList: [],
      dataLoading: false,
      tableColumns: [
        { type: 'selection', width: 60, align: 'center' },
        { title: '服务费名称', align: 'center', key: 'name' },
        { title: '类型', align: 'center', key: 'typeName' },
        {
          title: '刊例价',
          align: 'center',
          key: 'price',
          render: (h, { row }) => {
            return h('span', `${row.price} / ${row.unitQuantityName} （按${row.unitName}）`)
          }
        },
        {
          title: '结算价',
          align: 'center',
          key: 'price',
          render: (h, { row }) => {
            return h('span', `${row.statementPrice} / ${row.unitQuantityName} （按${row.unitName}）`)
          }
        }
        // {
        //   title: '状态',
        //   align: 'center',
        //   render: (h, { row }) => {
        //     return h('Tag', {
        //       props: { color: row.enabled ? 'success' : 'error' }
        //     }, row.enabled ? '启用' : '禁用')
        //   }
        // }
      ]
    }
  },
  computed: {
    showList () {
      const results = cloneDeep(this.costDictList)
      if (this.serviceCostList.length) {
        results.forEach(item => {
          if (this.serviceCostList.some(x => x.id === item.id)) {
            Object.assign(item, { _checked: true })
          }
        })
      }
      return results
    }
  },
  methods: {
    init (data) {
      if (data) {
        this.productId = data.productId
        this.productskuId = data.id
        this.getCurCostData()
      }
      this.query.keyword = ''
      this.query.publisherId = this.publisherId
      this.getCostDictData()
      this.showModal = true
    },
    fomartMoney (num) {
      return toMoney(num)
    },
    getCurCostData () {
      this.serviceCostList = []
      getServiceItemBySku({ skuId: this.productskuId }).then(res => {
        if (res && !res.errcode) {
          this.serviceCostList = res
          this.serviceItemIds = res.map(x => x.id)
        }
      })
    },
    /**
     * 获取服务费配置字典列表
     */
    getCostDictData () {
      this.costDictList = []
      this.dataLoading = true
      list(this.query).then(res => {
        if (res && !res.errcode) {
          this.costDictList = res
        }
      }).finally(() => { this.dataLoading = false })
    },
    handleSearch () {
      this.getCostDictData()
    },
    onSelectionChange (selection) {
      this.serviceItemIds = selection.map(x => x.id)
    },
    handleSaveProduce () {
      this.$Modal.confirm({
        title: '操作提示',
        content: '确认信息无误，并保存？',
        onOk: () => {
          updateServiceItem({ skuId: this.productskuId, serviceItemIds: JSON.stringify(this.serviceItemIds) }).then(res => {
            if (res && res.errcode === 0) {
              this.showModal = false
              this.$Notice.success({ desc: '操作成功' })
              this.saveSuccessFun()
            }
          })
        }
      })
    }
  }
}
</script>

<style></style>
