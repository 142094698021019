import { getByCategory } from '@/api/product/product'
import { getCompany } from '@/api/os/company'

export const productMixins = {
  data () {
    return {
      categoryType: 1,
      categoryArray: [],
      productArray: []
    }
  },
  computed: {
    userInfo () {
      return this.$store.getters.token.userInfo
    }
  },
  methods: {
    /**
     * 获取产品数据
     * @param {Object} params
     * @returns
     */
    getProductData (params) {
      if (!params) {
        return false
      }

      return new Promise((resolve) => {
        getByCategory({ categoryId: params.categoryId }).then(res => {
          if (res && !res.errcode) {
            this.productArray = res
            if (params.categoryId === 7) {
              // 套装媒体补执行
              this.$store.commit('set_product_array', [])
            } else {
              // 套装媒体补执行
              this.$store.commit('set_product_array', this.productArray)
            }
            resolve(res)
          }
        })
      })
    },
    /**
    * 获取公司信息
    */
    getCompanyInfo () {
      // 只能在当前单页面使用，避免多次调用接口
      if (this.companyInfo && this.companyInfo.length) {
        return false
      }
      getCompany({ companyId: this.userInfo.companyId, publisherId: this.userInfo.publisherId }).then(res => {
        if (res && !res.errcode) {
          this.companyInfo = res
        }
      })
    }
  }
}
