<template>
  <div>
    <div class="m-b-10" style="color:#FA8B59">提示：只支持图片文件上传，单次上传所有文件大小不超过100M，文件上传格式为：.jpg .png .jpeg
      .img。</div>
    <div class="m-b-10 box-flex">
      <div class="m-r-5">
        <Upload multiple action="" :format="['img', 'jpg', 'png', 'jpeg']" accept="image/*"
          :before-upload="handleBeforeUpload">
          <i-button icon="ios-cloud-upload-outline" type="success" size="small">选择示意图文件</i-button>
        </Upload>
      </div>
      <div>
        <i-button type="primary" size="small" :loading="btnLoading" :disabled="uploadFileList.length === 0"
          @click="handleUpload">{{ btnLoading ? '文件上传中...' : '确定并上传' }}</i-button>
      </div>
    </div>
    <div class="m-t-10" v-if="uploadFileList.length">
      <p class="m-b-10">本次上传文件</p>
      <div class="demo-upload-list" v-for="(file, index) in uploadFileList" :key="'uploadFile_' + index">
        <img :src="getbasesrc(file)" />
        <div class="demo-upload-list-cover">
          <Icon type="ios-eye-outline" @click.native="handleView(file, true)"></Icon>
          <Icon type="ios-trash-outline" @click.native="handleRemove(file,true)"></Icon>
        </div>
      </div>
    </div>
    <div class="m-t-10" v-if="productDiagramArray.length">
      <p class="m-b-10">已上传文件</p>
      <div class="demo-upload-list" v-for="(file, index) in productDiagramArray" :key="'diagram_' + index">
        <img :src="file.ossLocation" />
        <div class="demo-upload-list-cover">
          <Icon type="ios-eye-outline" @click.native="handleView(file)"></Icon>
          <Icon type="ios-trash-outline" @click.native="handleRemove(file)"></Icon>
        </div>
      </div>
    </div>
    <Modal v-model="showModal" width="800" footer-hide>
      <div>
        <img :src="bigImageUrl" width="760" />
      </div>
    </Modal>
  </div>
</template>
<script>
import axios from 'axios'
import { getStorage } from '@/utils/storage'
import { getPhotoBySkuid, deletePhotoById } from '@/api/product/productskuedit'
export default ({
  data () {
    return {
      uploadFileList: [],
      produtSkuid: null,
      productDiagramArray: [],
      showModal: false,
      bigImageUrl: '',
      btnLoading: false
    }
  },
  methods: {
    initData (skuId) {
      this.uploadFileList = []
      this.productDiagramArray = []
      this.bigImageUrl = ''
      this.produtSkuid = skuId
      this.getProductDiagramData()
    },
    async getProductDiagramData () {
      const result = await getPhotoBySkuid({ skuId: this.produtSkuid })
      this.productDiagramArray = (result && !result.errcode) ? result : []
    },
    getbasesrc (file) {
      return window.URL.createObjectURL(file)
    },
    handleView (file, isNew = false) {
      this.bigImageUrl = isNew ? this.getbasesrc(file) : file.ossLocation
      this.showModal = true
    },
    handleRemove (file, isNew = false) {
      if (isNew) {
        this.uploadFileList.splice(this.uploadFileList.indexOf(file), 1)
      } else {
        deletePhotoById({ id: file.id }).then(res => {
          if (res && res.errcode === 0) {
            this.getProductDiagramData()
            this.$Notice.success({ title: '操作成功' })
          }
        })
      }
    },
    handleBeforeUpload (file) {
      this.uploadFileList.push(file)
      return false
    },
    handleUpload () {
      const that = this
      const uploadData = new FormData()
      uploadData.append('skuId', that.produtSkuid)
      that.uploadFileList.forEach(function (file) {
        uploadData.append('files', file)
      })

      that.btnLoading = true
      axios.post(process.env.VUE_APP_API_URL_V2 + '/ooh-product/v1/productskuedit/updateskuphoto',
        uploadData,
        {
          headers: {
            'Content-Type': 'multipart/form-data',
            'ooh-auth': getStorage('ooh-token')
          },
          withCredentials: true
        })
        .then((res) => {
          that.btnLoading = false
          if (res.data.errcode === 0) {
            that.uploadFileList = []
            that.getProductDiagramData()
            that.$Notice.success({ title: '操作成功' })
          } else {
            that.$Notice.error({ desc: res.data.errmsg })
          }
        }).catch(error => {
          that.$Notice.error({ desc: error })
        })
    }
  }
})
</script>
<style scoped>
.box-flex {
  display: flex;
  flex-wrap: wrap;
}

.demo-upload-list {
  display: inline-block;
  width: 120px;
  height: 80px;
  text-align: center;
  line-height: 80px;
  border: 1px solid transparent;
  border-radius: 4px;
  overflow: hidden;
  background: #fff;
  position: relative;
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.2);
  margin-right: 4px;
  margin-top: 5px;
}

.demo-upload-list img {
  width: 100%;
  height: 100%;
}

.demo-upload-list-cover {
  display: none;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: rgba(0, 0, 0, 0.6);
}

.demo-upload-list:hover .demo-upload-list-cover {
  display: block;
}

.demo-upload-list-cover i {
  color: #fff;
  font-size: 20px;
  cursor: pointer;
  margin: 0 2px;
}
</style>
