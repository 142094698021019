<template>
  <div>
    <Modal v-model="showModal" width="80%" footer-hide>
      <p slot="header" class="text-center">电子媒体规则配置</p>
      <div class="m-b-10">
        <Button type="success" size="small" @click="onAdd">新增规则配置</Button>
      </div>
      <Table size="small" stripe :data="list" :columns="tableColumns" :loading="dataLoading"></Table>
    </Modal>
    <modify-ele-media-config ref="modify" :newAdd="newAdd" :publisherId="publisherId" :onSuccess="getTableData" />
  </div>
</template>

<script>

import { list, delSetting } from '@/api/product/electricProducts'
import ModifyEleMediaConfig from './ModifyEleMediaConfig.vue'
export default {
  props: {
    publisherId: [Number]
  },
  components: {
    ModifyEleMediaConfig
  },

  data () {
    return {
      showModal: false,
      newAdd: true,
      dataLoading: false,
      list: [],
      tableColumns: [
        { title: '#', align: 'center', key: 'id' },
        { title: '名称', align: 'center', key: 'name' },
        {
          title: '包含产品',
          align: 'center',
          render: (h, { row }) => {
            const products = JSON.parse(row.productNames)
            const html = []
            products.forEach(item => {
              html.push(h('p', item))
            })
            return h('div', html)
          }
        },
        { title: '最小售卖规格（秒）', align: 'center', key: 'durations' },
        { title: '每天周期数（次）', align: 'center', key: 'periods' },
        {
          title: '起止时间',
          align: 'center',
          render: (h, { row }) => {
            return h('span', `${row.startTime} - ${row.endTime}`)
          }
        },
        {
          title: '操作',
          align: 'center',
          render: (h, { row }) => {
            const html = [
              h('a', {
                style: {
                  marginRight: '5px'
                },
                on: {
                  click: () => {
                    this.onModify(row)
                  }
                }
              }, '编辑'),
              h('a', {
                style: {
                  color: '#ef4f4f'
                },
                on: {
                  click: () => {
                    this.onDel(row.id)
                  }
                }
              }, '删除')
            ]
            return h('div', html)
          }
        }
      ]
    }
  },
  methods: {
    init () {
      this.showModal = true
      this.getTableData()
    },
    getTableData () {
      this.list = []
      this.dataLoading = true
      list({ publisherId: this.publisherId }).then(res => {
        if (res && !res.errcode) {
          this.list = res
        }
      }).finally(() => { this.dataLoading = false })
    },
    onAdd () {
      this.newAdd = true
      this.$nextTick(() => {
        this.$refs.modify.init()
      })
    },
    onModify (params) {
      this.newAdd = false
      this.$nextTick(() => {
        this.$refs.modify.init(params)
      })
    },
    onDel (id) {
      this.$Modal.confirm({
        title: '操作提示',
        content: '确定要删除该数据？',
        onOk: () => {
          delSetting({ id }).then(res => {
            if (res && res.errcode === 0) {
              this.$Notice.success({ desc: '操作成功' })
              this.getTableData()
            }
          })
        }
      })
    }
  }
}
</script>

<style></style>
