<template>
  <div>
    <Modal v-model="modifyModal" width="800" footer-hide>
      <p slot="header" class="text-center">{{ newAdd ? '新增资源服务费配置' : '编辑资源服务费配置' }}</p>
      <Form ref="formValidate" :model="settingModel" :rules="ruleValidate" :label-width="130">
        <!-- <FormItem label="配置方式">
          <RadioGroup v-model="seettingType" size="small" type="button" button-style="solid"
            @on-change="onChangeSettingType">
            <Radio v-for="item in settingTypeArray" :key="item.value" :label="item.value" :disabled="newAdd==false">{{ item.name }}</Radio>
          </RadioGroup>
        </FormItem>
        <FormItem v-if="seettingType === 1" label="资源类型" prop="resourceTypeId">
          <i-select size="small" placeholder="资源类型（可通过关键字搜索）" clearable filterable v-model="settingModel.resourceTypeId">
            <OptionGroup v-for="item in resourceTypeArray" :key="'re_parent_' + item.id" :label="item.name">
              <Option v-for="child in item.children" :value="child.id" :key="'re_child' + child.id">{{ child.name }}
              </Option>
            </OptionGroup>
          </i-select>
        </FormItem>
        <FormItem v-else label="具体资源" prop="resourceId">
          <Button type="primary" size="small" class="m-r-10" @click="onChoiceRes">选择资源</Button>
          <Tag v-show="settingModel.resourceId > 0">{{ selectedResCode }}</Tag>
        </FormItem> -->
        <FormItem label="服务费名称" prop="name">
          <Input v-model="settingModel.name" size="small" placeholder="填写服务费名称，例如：十二封灯箱制作费"/>
        </FormItem>
        <FormItem label="服务费类型" prop="type">
          <Select v-model="settingModel.type" size="small" placeholder="选择服务费类型">
            <Option v-for="unit in serviceTypeArray" :value="unit.value" :key="'sType_' + unit.value">{{
              unit.name
            }}
            </Option>
          </Select>
        </FormItem>
        <FormItem label="计费方式" prop="unit">
          <Select v-model="settingModel.unit" size="small" placeholder="选择计费方式，如：按整块">
            <Option v-for="unit in unitArray" :value="unit.value" :key="'unit_' + unit.value">{{
              unit.name
            }}
            </Option>
          </Select>
        </FormItem>
        <FormItem label="刊例价" prop="price">
          <InputNumber :min="0" v-model="settingModel.price"
            :formatter="value => `￥ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')"
            :parser="value => value.replace(/\￥\s?|(,*)/g, '')" size="small" style="width:100%" placeholder="输入刊例价">
          </InputNumber>
        </FormItem>
        <FormItem label="结算价" prop="statementPrice">
          <InputNumber :min="0" v-model="settingModel.statementPrice"
            :formatter="value => `￥ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')"
            :parser="value => value.replace(/\￥\s?|(,*)/g, '')" size="small" style="width:100%" placeholder="输入结算价">
          </InputNumber>
        </FormItem>
        <FormItem class="text-right m-t-10">
          <Button type="text" class="m-r-5" @click="modifyModal = false">取消</Button>
          <Button type="primary" @click="handleSave">确定</Button>
        </FormItem>
      </Form>
    </Modal>
    <Modal v-model="resourceShow" width="800">
      <p slot="header" class="text-center">选择资源</p>
      <ResourceList ref="resource" :assetArray="assetArray" :option="true" @on-choice-res="onAddRes" />
    </Modal>
  </div>
</template>

<script>
// import { getResourceTypeList } from '@/api/product/asset'
import { addServicePrice, editServicePrice } from '@/api/product/serviceItemPrices'
import ResourceList from '../common/ResourceList.vue'
export default {
  props: {
    publisherId: [Number],
    newAdd: {
      type: Boolean,
      default: false
    },
    onSuccess: {
      type: Function,
      default: () => { }
    }
  },
  components: { ResourceList },
  data () {
    return {
      modifyModal: false,
      settingModel: {
        id: undefined,
        name: '',
        price: 0,
        publisherId: null,
        resourceId: undefined,
        statementPrice: 0,
        type: undefined,
        typeName: '',
        unit: undefined,
        unitName: ''
      },
      // seettingType: 1,
      // settingTypeArray: [
      //   { value: 1, name: '按资源类型' },
      //   { value: 2, name: '按具体资源' }
      // ],
      serviceTypeArray: [
        { value: 1, name: '上刊' },
        { value: 2, name: '监播' },
        { value: 3, name: '制作' }
      ],
      unitArray: [
        { value: 1, name: '面数' },
        { value: 2, name: '面积' },
        { value: 3, name: '字' },
        { value: 5, name: '整体' }
      ],
      // resourceTypeArray: [],
      productArray: [],
      ruleValidate: {
        name: [
          { required: true, type: 'string', message: ' ', trigger: 'blur' }
        ],
        resourceId: [
          { required: true, type: 'number', message: ' ' }
        ],
        type: [
          { required: true, type: 'number', message: ' ', trigger: 'change' }
        ],
        unit: [
          { required: true, type: 'number', message: ' ', trigger: 'change' }
        ],
        price: [
          { required: true, type: 'number', message: ' ', trigger: 'blur' }
        ],
        statementPrice: [
          { required: true, type: 'number', message: ' ', trigger: 'blur' }
        ]
      },
      resourceShow: false
      // selectedResCode: ''
    }
  },
  computed: {
    assetArray () {
      return this.$store.state.productManage.assetArray
    }
  },
  methods: {
    init (data) {
      // this.getResourceTypeData()
      if (this.newAdd) { // 新增
        this.seettingType = 1
        this.selectedResCode = ''
        Object.assign(this.$data.settingModel, this.$options.data().settingModel)
      } else { // 编辑
        if (data) {
          Object.keys(this.settingModel).forEach(key => {
            this.settingModel[key] = data[key]
          })
          this.seettingType = this.settingModel.resourceId === -1 ? 1 : 2
          this.selectedResCode = data.resourceCode
        }
      }
      this.settingModel.publisherId = this.publisherId
      this.modifyModal = true
    },
    // onChangeSettingType () {
    //   if (this.seettingType === 1) {
    //     this.settingModel.resourceId = undefined
    //     this.selectedResCode = ''
    //   } else {
    //     this.settingModel.resourceTypeId = undefined
    //   }
    // },
    // getResourceTypeData () {
    //   const assetIds = this.assetArray.map(x => x.id) || []
    //   this.resourceTypeArray = []
    //   getResourceTypeList({ assetIds: JSON.stringify(assetIds) }).then(res => {
    //     if (res && !res.errcode) {
    //       this.resourceTypeArray = res
    //     }
    //   })
    // },
    onChoiceRes () {
      this.resourceShow = true
      this.$nextTick(() => {
        this.$refs.resource.init()
      })
    },
    onAddRes (data) {
      this.selectedResCode = data.code
      this.settingModel.resourceId = data.id
      this.$Notice.success({ desc: `已成功选择资源：${data.code}` })
      this.resourceShow = false
    },
    handleSave () {
      this.$refs.formValidate.validate((valid) => {
        if (valid) {
          this.$Modal.confirm({
            title: '操作提示？',
            content: '确定信息无误，并提交？',
            onOk: () => {
              const type = this.serviceTypeArray.find(x => x.value === this.settingModel.type)
              const unit = this.unitArray.find(x => x.value === this.settingModel.unit)
              this.settingModel.typeName = type ? type.name : ''
              this.settingModel.unitName = unit ? unit.name : ''
              if (this.newAdd) {
                this.funAdd(this.settingModel)
              } else {
                this.settingModel.resourceId = this.settingModel.resourceId === -1 ? undefined : this.settingModel.resourceId
                this.funEdit(this.settingModel)
              }
            }
          })
        }
      })
    },
    funAdd (data) {
      addServicePrice(data).then(res => {
        if (res && res.errcode === 0) {
          this.$Notice.success({ desc: '操作成功' })
          this.onSuccess()
        }
        this.modifyModal = false
      })
    },
    funEdit (data) {
      editServicePrice(data).then(res => {
        if (res && res.errcode === 0) {
          this.$Notice.success({ desc: '操作成功' })
          this.onSuccess()
        }
        this.modifyModal = false
      })
    }
  }
}
</script>
