<template>
  <div>
    <Modal v-model="showModal" width="80%" footer-hide>
      <p slot="header" class="text-center">资源服务费配置</p>
      <Row :gutter="8" class="m-b-10">
        <i-col span="3">
          <Select v-model="query.enabled" :clearable="true" size="small" placeholder="状态">
            <Option v-for="item in enabledArray" :key="'enabled_' + item.value" :value="item.value">{{ item.name }}
            </Option>
          </Select>
        </i-col>
        <i-col span="6">
          <i-input v-model="query.keyword" size="small" placeholder="输入服务费名称关键字"></i-input>
        </i-col>
        <i-col span="15">
          <i-button size="small" class="m-r-5" type="primary" icon="ios-search" @click="handleSearch">搜索</i-button>
          <Button type="success" size="small" @click="onAdd">新增资源服务费配置</Button>
        </i-col>
      </Row>
      <Table size="small" max-height="580" stripe :data="list" :columns="tableColumns" :loading="dataLoading"></Table>
    </Modal>
    <Modify ref="modify" :newAdd="newAdd" :publisherId="publisherId" :onSuccess="getTableData" />
  </div>
</template>

<script>

import { list, changeEnabled } from '@/api/product/serviceItemPrices'
import Modify from './Modify.vue'
export default {
  props: {
    publisherId: [Number]
  },
  components: {
    Modify
  },

  data () {
    return {
      showModal: false,
      newAdd: true,
      enabledArray: [
        { value: 1, name: '启用' },
        { value: 0, name: '禁用' }
      ],
      query: {
        publisherId: undefined,
        enabled: undefined,
        keyword: ''
      },
      dataLoading: false,
      list: [],
      tableColumns: [
        { title: '#', align: 'center', key: 'id' },
        { title: '服务费名称', align: 'center', key: 'name' },
        { title: '类型', align: 'center', key: 'typeName' },
        {
          title: '刊例价',
          align: 'center',
          key: 'price',
          render: (h, { row }) => {
            return h('span', `${row.price} / ${row.unitQuantityName} （按${row.unitName}）`)
          }
        },
        {
          title: '结算价',
          align: 'center',
          key: 'price',
          render: (h, { row }) => {
            return h('span', `${row.statementPrice} / ${row.unitQuantityName} （按${row.unitName}）`)
          }
        },
        {
          title: '状态',
          align: 'center',
          render: (h, { row }) => {
            return h('Tag', {
              props: { color: row.enabled ? 'success' : 'error' }
            }, row.enabled ? '启用' : '禁用')
          }
        },
        {
          title: '操作',
          align: 'center',
          render: (h, { row }) => {
            const html = [
              h('a', {
                style: {
                  marginRight: '5px'
                },
                on: {
                  click: () => {
                    this.onModify(row)
                  }
                }
              }, '编辑'),
              h('a', {
                style: {
                  color: row.enabled ? '#ef4f4f' : ''
                },
                on: {
                  click: () => {
                    this.onUpdateStatus(row.id, !row.enabled)
                  }
                }
              }, row.enabled ? '禁用' : '启用')
            ]
            return h('div', html)
          }
        }
      ]
    }
  },
  methods: {
    init () {
      this.showModal = true
      this.query.keyword = ''
      this.query.enabled = undefined
      this.query.publisherId = this.publisherId
      this.getTableData()
    },
    getTableData () {
      this.list = []
      this.dataLoading = true
      list(this.query).then(res => {
        if (res && !res.errcode) {
          this.list = res
        }
      }).finally(() => { this.dataLoading = false })
    },
    handleSearch () {
      this.getTableData()
    },
    onAdd () {
      this.newAdd = true
      this.$nextTick(() => {
        this.$refs.modify.init()
      })
    },
    onModify (params) {
      this.newAdd = false
      this.$nextTick(() => {
        this.$refs.modify.init(params)
      })
    },
    onUpdateStatus (id, status) {
      this.$Modal.confirm({
        title: '操作提示',
        content: `确定要${status === true ? '启用' : '禁用'}此项配置`,
        onOk: () => {
          changeEnabled({ id, enabled: status }).then(res => {
            if (res && res.errcode === 0) {
              this.$Notice.success({ desc: '操作成功' })
              this.getTableData()
            }
          })
        }
      })
    }
  }
}
</script>

<style></style>
