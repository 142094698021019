<template>
  <div>
    <Modal v-model="svgModalShow" :width="1000">
      <p slot="header" class="text-center">查看产品图示</p>
      <div>
        <h4 class="workplatform-title m-t-10 m-b-10">资源清单</h4>
        <p class="p-b-5">
          <Tag v-for="item in curProductInfo.fixResourceList" :key="'res_' + item.id">{{ item.resourceCode }}</Tag>
        </p>
      </div>
      <div>
        <h4 class="workplatform-title m-t-10 m-b-10">图列描述</h4>
        <Tabs v-model="curTab" @on-click="handleClickTab">
          <TabPane label="产品位置" name="1">
            <ResourceSvg ref="resourceSvg"></ResourceSvg>
          </TabPane>
          <TabPane label="产品示意图" name="2">
            <ProductDiagram ref="productDiagram"></ProductDiagram>
          </TabPane>
        </Tabs>

      </div>
    </Modal>
  </div>
</template>
<script>
import ResourceSvg from '@/components/svg/SvgResource'
import ProductDiagram from './ProductDiagram.vue'
export default {
  components: {
    ResourceSvg,
    ProductDiagram
  },
  data () {
    return {
      svgModalShow: false,
      curTab: '1',
      curProductInfo: []
    }
  },
  methods: {
    showModal (params) {
      if (!params) {
        return false
      }
      this.curTab = '1'
      this.curProductInfo = params
      const tagResoursArray = params.fixResourceList.map(x => x.deviceId)
      this.$refs.resourceSvg.bindThemeData(params.id, tagResoursArray)
      this.svgModalShow = true
    },
    handleClickTab () {
      if (parseInt(this.curTab) === 2) {
        this.$refs.productDiagram.initData(this.curProductInfo.id)
      }
    }
  }
}
</script>
