<template>
  <div>
    <Row :gutter="8" class="m-b-5">
      <i-col span="4">
        <Select v-model="resQuery.assetId" :cleable="false" size="small" placeholder="选择资产"
          @on-change="handleChangeAsset">
          <Option v-for="item in assetArray" :key="'aid_' + item.id" :value="item.id">{{ item.name }}</Option>
        </Select>
      </i-col>
      <i-col span="4">
        <i-select size="small" placeholder="站点" clearable v-model="resQuery.stationId">
          <i-option :value="item.id" v-for="item in stationArray" :key="item.id">{{ item.name }}</i-option>
        </i-select>
      </i-col>
      <i-col span="4">
        <i-select size="small" placeholder="位置" clearable v-model="resQuery.position">
          <i-option v-for="item in positionArray" :value="item.value" :key="'pos_' + item.value">{{ item.name
          }}</i-option>
        </i-select>
      </i-col>
      <i-col span="4">
        <i-select size="small" placeholder="资源类型(可搜索)" clearable filterable v-model="resQuery.resourcetypeId">
          <OptionGroup v-for="item in resourceTypeArray" :key="'re_parent_' + item.id" :label="item.name">
            <Option v-for="child in item.children" :value="child.id" :key="'re_child' + child.id">{{ child.name }}
            </Option>
          </OptionGroup>
        </i-select>
      </i-col>
      <i-col span="2">
        <i-select size="small" placeholder="状态" clearable v-model="resQuery.enabled">
          <i-option v-for="item in statusArray" :key="'status_' + item.value" :value="item.value">{{ item.name
          }}</i-option>
        </i-select>
      </i-col>

      <i-col span="4">
        <i-input size="small" placeholder="关键字：支持名称查询" v-model="resQuery.keyword"></i-input>
      </i-col>
      <i-col span="2">
        <i-button size="small" class="m-r-5" type="primary" icon="ios-search" @click="handleSearch">搜索</i-button>
      </i-col>
    </Row>
    <slot name="option"></slot>
    <!-- <div class="m-b-5">
      <Button type="success" size="small" :disabled="selectedResArray.length === 0"
        @click="handleConfirmRes">确认加入</Button>
    </div> -->
    <Table size="small" stripe :data="resList" :columns="resColumns" :loading="dataLoading">
    </Table>
    <div class="paging_style">
      <Page size="small" :total="resTotal" :page-size="resQuery.pageSize" show-total show-elevator
        :current="resQuery.pageNumber" @on-change="handlePageChange"></Page>
    </div>
  </div>
</template>

<script>
import { getPositions, getResourcePage } from '@/api/product/resource'
import { getResourceTypeList } from '@/api/product/asset'
import { getStationByAsset } from '@/api/product/station'
export default {
  props: {
    assetArray: [Array],
    selection: {
      type: Boolean,
      default: false
    },
    option: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      stationArray: [],
      positionArray: [],
      resourceTypeArray: [],
      statusArray: [
        { value: 1, name: '可用' },
        { value: 0, name: '不可用' }
      ],
      resQuery: {
        pageSize: 15,
        pageNumber: 1,
        assetId: null,
        stationId: null,
        enabled: null,
        position: null,
        resourcetypeId: null,
        keyword: ''
      },
      resList: [],
      resTotal: 0,
      resColumns: [],
      dataLoading: false
    }
  },
  methods: {
    init () {
      this.resQuery.assetId = this.assetArray[0].id
      this.assemblyColumns()
      this.initStationArray()
      this.getPositionData()
      this.getResourceTypeData()
      this.getResData()
    },
    assemblyColumns () {
      const columns = []
      if (this.selection) {
        columns.push({ type: 'selection', width: 60, align: 'center' })
      }
      columns.push(
        { title: '编号', align: 'center', key: 'code' },
        { title: '所属站点', align: 'center', key: 'stationName' },
        { title: '资源类型', align: 'center', key: 'resourcetypeName' },
        {
          title: '状态',
          align: 'center',
          key: 'enabled',
          render: (h, params) => {
            return h('span', params.row.enabled ? '可用' : '不可用')
          }
        }
      )
      if (this.option) {
        columns.push(
          {
            title: '操作',
            align: 'center',
            render: (h, { row }) => {
              return h('a', {
                on: {
                  click: () => {
                    this.onChoice(row)
                  }
                }
              }, '选择')
            }
          }
        )
      }
      this.resColumns = columns
    },
    // 初始化站点选项
    initStationArray () {
      this.resQuery.stationId = null
      getStationByAsset({ assetId: this.resQuery.assetId }).then((res) => {
        if (res && !res.errcode) {
          this.stationArray = res
        } else {
          this.stationArray = []
        }
      })
    },
    getPositionData () {
      getPositions({ assetIds: JSON.stringify([this.resQuery.assetId]) }).then(res => {
        if (res && !res.errcode) {
          this.positionArray = res
        } else {
          this.positionArray = []
        }
      })
    },
    /**
    * 获取资源类型数据
    */
    getResourceTypeData () {
      getResourceTypeList({ assetId: this.resQuery.assetId }).then(res => {
        if (res && !res.errcode) {
          this.resourceTypeArray = res
        } else {
          this.resourceTypeArray = []
        }
      })
    },
    getResData () {
      this.dataLoading = true
      getResourcePage(this.resQuery).then(res => {
        if (res && !res.errcode) {
          this.resList = res.list
          this.resTotal = res.totalRow
          this.resQuery.pageNumber = res.pageNumber
          // 显示多选框
          if (this.selection) {
            this.resList.forEach(item => {
              item._checked = false
              if (this.selectedResArray.some(x => x.id === item.id)) {
                item._checked = true
              }
            })
          }
        } else {
          this.resList = []
          this.resTotal = 0
          this.resQuery.pageNumber = 1
        }
      }).finally(() => { this.dataLoading = false })
    },
    handleChangeAsset () {
      this.initStationArray()
      this.getPositionData()
      this.handleSearch()
    },
    handlePageChange (curPage) {
      // this.selectedResArray = []
      this.prevSelectionArray = []
      this.resQuery.pageNumber = curPage
      this.getResData()
    },
    handleSearch () {
      // this.selectedResArray = []
      this.resQuery.pageNumber = 1
      this.getResData()
    },
    onChoice (params) {
      this.$emit('on-choice-res', params)
    }
  }
}
</script>
